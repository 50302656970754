@media print {

	@page {
		size: A4;
		margin: 10mm;
		margin-top: 20mm;
		margin-bottom: 20mm;
	}

	body {
		font-size: 12pt;
		line-height: 1.5;
	}

	.page-break {
		page-break-before: always;
		break-before: page;
	}

	.visible-print {
		display: block !important;
	}

	.hidden-print {
		display: none !important;
	}

	#similarunits,
	#lastviewed-units,
	#settings,
	.header-boxes,
	.footer-v1,
	.fa-chevron-right,
	.navbar-v1 {
		display: none;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: arial, helvetica, sans-serif;
	}

	h1,
	.h1 {
		font-size: 18pt;
	}

	h2,
	.h2 {
		font-size: 16pt;
	}

	h3,
	.h3 {
		font-size: 14pt;
	}

	h4,
	.h4 {
		font-size: 13pt;
	}

	h5,
	.h5 {
		font-size: 12pt;
	}

	.container {
		display: block;
	}

	.unit-title {
		margin-top: 0px;
	}

	.unit-pic {
		width: 100%;
		aspect-ratio: 4 / 3;
		object-fit: cover;
	}

	.col-print-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.unit-page-layout-v1 {

		margin-top: 0px !important;
		padding-top: 0px !important;

		.showmore {
			position: unset !important;
			box-shadow: none !important;
			height: unset !important;
		}

		.title {
			display: none !important;
		}

		.header {
			display: none !important;
		}

		.content {
			position: unset !important;
			height: unset !important;
			padding: 10px;
		}

		.unit-sub-title-v1 {
			.title {
				display: block !important;
			}
		}
	}



	.section-btn {
		border-radius: 0px !important;
		border: none !important;
		border-bottom: 1px solid var(--mobile-section-btn-border-color) !important;
	}

}

.title-img {
	width: 100%;
	height: auto;
}