.search-map-pane-v1 {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;
	min-width: 0;
	min-height: 0;
	flex-direction: inherit;

	.header {
		display: none;
	}

	.map-unit-preview {
		padding-top: 3px;
	}

	.flex {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 0;
		-ms-flex: 1 1;
		flex: 1 1 0;
		min-width: 0;
		min-height: 0;

		@media (min-width: 993px) {
			height: calc(100vh -178px);
		}


		@media (max-width:992px) {
			height: calc(100vh -178px);
		}


	}

	.unit-title {
		font-size: var(--font-size-main);
		padding-top: 10px;
		font-weight: 600;
	}

	.unit-place {
		padding-top: 10px;
	}

	.preview-img {
		max-width: 320px;
		position: relative;

		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 180px;
			width: 100%;

			@media(max-width:576px) {
				height: 150px;
			}
		}

		.rentRate {
			position: absolute;
			top: 0px;
			right: 0px;
			background-color: #fff;
			text-decoration: none;
			color: var(--font-color-main);
			padding-left: 5px;
			padding-bottom: 2px;


			&:hover {
				text-decoration: none;
			}

			.h3 {
				font-size: 18px
			}

			.main-price {
				&.h5 {
					color: var(--color-grey-dark);
					text-decoration: line-through;
					font-size: 14px;

				}
			}

			.discount-price {
				&.h3 {
					font-size: 18px
				}
			}

			.reducedprice {
				color: var(--color-red);
			}
		}

		.rating {
			position: absolute;
			right: 3px;
			bottom: 3px;
			z-index: 2;
			font-size: var(--font-size-md);

			.avg {
				text-align: center;
			}

			.avg-symbol {
				background-color: var(--bg-color-feedback);
				color: var(--font-color-feedback);
				padding: 3px;
				border-radius: var(--border-radius);
				font-weight: normal;
				font-size: 14px;
			}

			.count {
				font-size: 12px;
				background: rgba(255, 255, 255, 0.8);
				padding: 3px;
				border-radius: 5px;
				margin-top: 2px;
				display: inline-block;
				text-decoration: none;
				color: var(--font-color-main);

				&:hover {
					text-decoration: none;
					color: var(--font-color-main);
				}
			}
		}
	}

	.unit-properties {

		margin-top: 10px;

		li {
			padding-right: 12px;
			display: inline-block;
			line-height: 20px;

			.fa,
			.far,
			.fas,
			.fa-sharp {
				width: 18px;

				.fa-stack {
					margin-left: -10px;
					line-height: 1.4em;
				}
			}
		}
	}


}