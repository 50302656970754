.static-view {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.immobilien {
			background-image: url(RESOURCE/img/blog_banner.webp);
		}

		&.ueber-uns {
			background-image: url(RESOURCE/img/about_banner.webp);
		}

		&.faq {
			background-image: url(RESOURCE/img/faq_banner.webp);
		}

		&.Ihr-Weg {
			background-image: url(RESOURCE/img/anrise_banner.webp);
		}

		&.vermieters {
			background-image: url(RESOURCE/img/vermieter_banner.webp);
		}

		&.contact {
			background-image: url(RESOURCE/img/contact_banner.webp);
		}
	}

	.sidebar_nav {
		padding: 15px;
		background-color: #fff;
		box-shadow: 0 2px 3px 1px rgb(99 99 99 / 14%);

		.listing {
			margin-bottom: 0;

			li {
				a {
					display: inline-block;
					width: 100%;
					padding: 10px 15px 10px 25px;
					/* background: var(--color-beige); */
					background: #faf8f2;
					text-decoration: none;
					margin-bottom: 1px;
					position: relative;

					&:hover {
						background: var(--color-primary);
						color: var(--color-white);

						&:before {
							color: var(--color-white);
						}
					}

					&.active {
						background: var(--color-primary);
						color: var(--color-white);

						&:before {
							color: var(--color-white);
						}
					}

					&:before {
						left: 14px;
						top: 13px;
						position: absolute;
						font-family: 'Font Awesome 6 Sharp';
						color: #3599b0;
						content: "\f054";
						position: absolute;
						left: 10px;
						top: 14px;
						font-size: 12px;
					}
				}
			}
		}
	}

	.sidebar_contact {
		h2 {
			font-size: 20px;
		}

		i {
			width: 17px;
			display: inline-block;
			color: #00426d;
			margin-right: 10px;

			img {
				width: 100%;
			}
		}

		ul {
			margin-bottom: 0;

			li {
				margin-bottom: 10px;

				a {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

	}
}