.voucher-v1 {
	margin-top: var(--page-margin-top);

	.voucher-close {
		float: right;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: .8;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		opacity: .5;
		border: none;
		background: transparent;
	}
}