.search-result-header-v1 {

	.found {
		font-weight: 400;
	}

	.srt-bl-top {
		.flex-align-center {

			.btn-filter {
				font-size: var(--font-size-md);
				padding-right: 10px;
				display: inline-block;
			}
		}
	}

	.srt-bl-top {
		padding-bottom: 10px;
	}

}