.unit-section-location-v1 {

	.desktop-map {
		height: 450px;
	}

	.location-description {
		margin-top: 20px;
	}

}