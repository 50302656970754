.mobile-pictures-v1 {
	width: 100%;
	bottom: 0;
	top: 0;
	position: fixed;
	padding: 0px;
	background-color: #fff;
	transition: all 0.8s ease 0s;
	z-index: 9999;
	right: -102%;
	height: 100vh;
	box-shadow: -3px 0 4px -4px rgba(124, 125, 126, 0.8);

	&.show {
		right: 0px;
		top: 0px;
	}

	.header {
		width: 100%;
		height: 50px;
		right: 0;
		top: 0;
		position: absolute;
		z-index: 1;
		cursor: pointer;
		color: var(--mobile-sidebar-font-color);
		border-bottom: 1px solid var(--mobile-sidebar-header-border-color);
		background-color: var(--mobile-sidebar-header-bg-color);

		.fa-xmark {
			position: absolute;
			top: 16px;
			right: 15px;
			font-size: 18px;
		}
	}

	.title {
		width: 100%;
		height: 50px;
		position: fixed;
		display: block;
		margin-bottom: 0;
		font-size: var(--mobile-sidebar-header-font-size);
		line-height: 50px;
		top: 0;
		z-index: 2;
		padding-left: 20px;
		pointer-events: none;
		color: var(--mobile-sidebar-header-font-color);

		.far,
		.fa,
		.fas,
		.fa-light,
		.fa-sharp {
			color: var(--mobile-sidebar-header-font-color);
			padding-right: 10px;
		}
	}

	.content {
		padding: 20px 20px;
		height: calc(100vh - 50px);
		overflow-y: auto;
		position: absolute;
		z-index: 99999;
		top: 50px;
		width: 100%;
		max-width: 100%;

		@media(max-width:460px) {
			padding: 10px;
		}

		div {
			width: 100%;
			margin-bottom: 10px;
			position: relative;

			img {
				width: 100%;
				height: auto;
				display: none;
				min-height: 100px;
			}

			.img-title {
				position: absolute;
				width: 100%;
				bottom: -10px;
				color: #fff;
				text-align: center;
				font-size: var(--font-size-sm);
				background: rgba(0, 0, 0, 0.4);
			}
		}
	}
}

.mobile-pictures-v1.show {
	.content {
		div {
			img {
				display: block;
			}
		}
	}
}